.infoMain {
    background-color: rgba(67, 40, 24, 1);
    padding: 30px 40px 80px 40px;
}

.infoMain>h4 {
    color: rgba(255, 230, 167, 1);
    font-size: 20px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    margin-bottom: 3px;
}

.infoMain>p {
    color: rgba(255, 230, 167, 1);
    font-size: 20px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    margin-top: 0px;
}

.infoCourses {
    background-color: rgba(255, 230, 167, 1);
    width: 80.5%;
    border-radius: 4px;
    padding: 10px 60px 20px 60px;
    margin: 0 auto;
    margin-top: 50px;
}

.infoCourses>div {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 40px;
}

.infoCourses>div>div {
    box-shadow: 0px 4px 4px 0px #00000040;
    background: #FFFFFFB2;
    width: 25%;
    border-radius: 8px;
    padding: 7px;
}

.infoCourses>h2 {
    font-size: 24px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    color: rgba(67, 40, 24, 1);
}

.infoCourses>div>div>h4 {
    font-size: 20px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    color: rgba(67, 40, 24, 1);
}

.infoCourses>div>div>p {
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    color: rgba(67, 40, 24, 1);
}

.infoCourses>h5 {
    font-size: 20px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    color: rgba(67, 40, 24, 1);
    text-align: left;
}

.infoCourses>h4 {
    font-size: 18px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    color: rgba(67, 40, 24, 1);
    text-align: left;
}

.infoCourses>button {
    width: 100%;
    margin-top: 20px;
    height: 40px;
    background-color: #6F1D1B;
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
}

.infoVideo {
    display: flex;
    justify-content: space-between;
    width: 89.6%;
    margin: 0 auto;
    margin-top: 50px;
    align-items: center;
}

.infoVideo>video {
    width: 90%;
    border-radius: 4px;
}

.infoVideo>div {
    text-align: left;
    padding-left: 40px;
}

.infoVideo>div>p {
    color: rgba(255, 230, 167, 1);
    font-size: 15px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    margin-top: 0px;
    line-height: 30px;
}

.aboutUs {
    background-color: #99582A;
    padding: 20px 30px;
    border-radius: 8px;
    color: #FFE6A7;
    font-family: "Roboto", sans-serif;
    margin: 0 auto;
    margin-top: 70px;
    margin-bottom: 70px;
    width: 85%;
    line-height: 30px;
}

.aboutUs>p {
    text-align: left;
}

@media (max-width: 480px) {
    .infoMain {
        padding: 20px;
    }

    .infoMain>h4,
    .infoMain>p {
        text-align: left;
        font-size: 18px;
    }

    .infoVideo {
        flex-direction: column;
        row-gap: 15px;
        width: 100%;
        align-items: flex-start;
    }

    .infoVideo>div {
        padding: 0px;
    }

    .infoVideo>video {
        width: 100%;
    }

    .infoCourses>h2 {
        font-size: 18px;
    }

    .infoCourses {
        padding: 10px;
        width: 95%;
    }

    .infoCourses>div {
        flex-direction: column;
        width: 100%;
    }

    .infoCourses>div>div {
        width: 95%;
    }

    .infoCourses>button {
        width: 100%;
    }
}