.mainHeader {
    padding: 30px 64px;
    background-color: #FFE6A7;
}

.headerNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.headerNav>img {
    width: 60px;
    height: 60px;
}

.headerNav>div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.headerNav>div>p {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: rgba(67, 40, 24, 1);
    cursor: pointer;
}

.headerNav>button {
    width: 146px;
    height: 40px;
    background-color: #6F1D1B;
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
}

.headMainDiv {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 30px 0px 30px 70px;
}

.headMainDiv>div>h2 {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 57px;
    text-align: left;
    color: rgba(67, 40, 24, 1);
    text-transform: capitalize;
}

.headMainDiv>div>h4 {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 24px;
    text-align: left;
    color: rgba(67, 40, 24, 1);
    text-transform: capitalize;
    margin: 15px 0px;
}

.headMainDiv>div>img {
    height: 400px;
}

.headMainDiv>div>div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    margin-top: 30px;
}

.headMainDiv>div>div>button:first-child {
    width: 146px;
    height: 40px;
    background-color: #6F1D1B;
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
}

.headMainDiv>div>div>button:last-child {
    width: 146px;
    height: 40px;
    background-color: #FFE6A7;
    border: 1px solid #6F1D1B;
    color: #6F1D1B;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
}

.headerNav>img.hamburger {
    display: none;
}


@media (max-width: 480px) {
    .mainHeader {
        padding: 10px;
    }

    .headerNav>div {
        display: none;
    }

    .headerNav>button {
        display: none;
    }

    .headMainDiv>div>img {
        display: none;
    }

    .headerNav>img.hamburger {
        width: 35px;
        height: 35px;
        display: block;
    }

    .headMainDiv {
        padding: 10px;
    }

    .headMainDiv>div>h2 {
        font-size: 38px;
    }

    .headMainDiv>div>h4 {
        font-size: 20px;
    }
}