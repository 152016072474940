.teacherMain {
    padding: 30px 64px;
    background-color: #FFE6A7;
}

.teacherMain>h2 {
    font-size: 18px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    color: rgba(67, 40, 24, 1);
    margin-bottom: 30px;
}

.teacherMain>div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 11px;
    width: 93%;
    margin: 0 auto;
}

.teacherMain>div>div {
    height: 430px;
    width: 22.5%;
    border-radius: 4px;
    background-color: rgba(187, 148, 87, 0.7);
    padding: 10px;
}

.teacherMain>div>div>img {
    width: 100%;
    height: 80%;
    border-radius: 4px;
    object-fit: cover;
}

.teacherMain>div>div>h4 {
    font-size: 16px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    color: rgba(67, 40, 24, 1);
    margin-bottom: 0px;
    margin-top: 8px;
}

.teacherMain>div>div>p {
    font-size: 16px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    color: rgba(67, 40, 24, 1);
    margin-bottom: 0px;
    margin-top: 8px;
}

@media (max-width: 480px) {
    .teacherMain {
        padding: 10px;
    }

    .teacherMain>div>div {
        width: 100%;
    }
}