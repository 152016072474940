.footerMain {
    padding: 30px 64px;
    background-color: rgba(255, 230, 167, 1);
}

.footerMain>h2 {
    font-size: 40px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    color: rgba(67, 40, 24, 1);
    margin-top: 15px;
    margin-bottom: 0px;
}

.footerMain>p {
    font-size: 20px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    color: rgba(67, 40, 24, 1);
    margin-top: 10px;
}

.footerMain>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    column-gap: 30px;
    margin: 0 auto;
}

.footerAddress {
    width: 37%;
    text-align: left;
    font-family: "Roboto", sans-serif;
    color: rgba(67, 40, 24, 1);
}

.footerAddress>p>a {
    color: rgba(67, 40, 24, 1);
    text-decoration: none;
}

.footerContact {
    text-align: left;
    font-family: "Roboto", sans-serif;
    color: rgba(67, 40, 24, 1);
}

.footerContact>p {
    display: flex;
    align-items: center;
    gap: 10px;
}

.footerContact>p>a {
    color: rgba(67, 40, 24, 1);
    text-decoration: none;
}

@media (max-width: 480px) {
    .footerMain {
        padding: 10px;
    }

    .footerMain>div {
        flex-direction: column;
        width: 90%;
    }

    .footerMain>h2 {
        font-size: 24px;
    }

    .footerMain>p {
        font-size: 18px;
    }

    .footerAddress {
        width: 100%;
    }
}