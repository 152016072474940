.galleryMain {
    padding: 30px 64px;
    background-color: rgba(67, 40, 24, 1);
}

.galleryMain>h2 {
    font-size: 18px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    color: rgba(255, 230, 167, 1);
    margin-bottom: 30px;
}

.imageRow {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 11px;
    width: 93%;
    margin: 0 auto;
}

.imageRow>div {
    width: 22%;
    border-radius: 4px;
    background-color: rgba(255, 230, 167, 1);
    padding: 10px;
}

.imageRow>div>img {
    width: 100%;
    height: 300px;
    border-radius: 4px;
    object-fit: cover;
    cursor: pointer;
}

.paginate {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    gap: 20px;
}

.paginate>button {
    width: 176px;
    height: 40px;
    background-color: rgba(255, 230, 167, 1);
    color: #6F1D1B;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
}

@media (max-width: 480px) {
    .galleryMain {
        padding: 10px;
    }

    .imageRow>div {
        width: 100%;
        height: 400px;
    }

    .imageRow>div>img {
        height: 400px;
    }
}